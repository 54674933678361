.GraphInfoContainerSeason {
    width: 230px;
    background-color: #3A1B52;
    border-radius: 11px;
    padding: 9px 10px;
}

.SampleInfoStyleSeason {
    width: 101px;
    height: 18px;
    background-color: #5F2F82;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TextSampleSeason {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #FFFFFF;
}

.TextPercentageSeasonTable {
    font-family: "Manrope";
    font-weight: 600;
    font-size: 17px;
    line-height: 23.22px;
    color: #FFF;
    margin-right: 5px;
}

.TopRaitingContainer {
    height: 20px;
    border-radius: 5px;
    padding: 0px 7px;
    gap: 10px;
    background-color: #88DD76;
    margin-top: 10px;
    margin-bottom: 10px;
}

.TopRaitingText {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    color: #383838;
}

.InfoTopSurvey {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #AB73D7;
    border-bottom: 1px solid #AB73D7;
    padding: 9px 0px;
    gap: 6px;
}

.RowInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.IndexRatingStyle {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    background-color: #3F3166;
    padding: 3px 6px;
}

.IndexRatingTextStyle {
    font-family: "Manrope";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFF;
}

.RangeTextSeason {
    font-family: "Manrope";
    font-weight: 600;
    font-size: 17px;
    line-height: 23.22px;
    color: #F8F9FA;
}

.RangeTextRating {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2%;
    color: #FFF;
    margin-left: 5px;
}
.TextPercentageRatingTable {
    font-family: "Manrope";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2%;
    color: #FFF;
    margin-right: 5px;
}