.StyleContainerRowTrends {
    flex-direction: column;
    margin-left: 175px;
    margin-right: 175px;
}

.TypeRowTrends {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TitleTypeTrends {
    font-size: 12px;
    font-family: "Manrope";
    font-weight: 500;
    line-height: 38px;
    margin-right: 50px;
    color: #241D38
}

.StyleLineTrends {
    width: 1050px;
    height: 2px;
    background-color: #B5A9DA;
    margin-top: 3px;
}

.GraphInfoContainerTrends {
    width: 230px;
    background-color: #F9F9F9;
    border-radius: 11px;
    padding: 9px 10px;
}

.SampleInfoStyleTrends {
    width: 101px;
    height: 18px;
    background-color: #EFEFEF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TextSampleTrends {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #989898;
}

.TitleTable {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #3B1D52;
    margin-top: 15px;
    margin-bottom: 15px;
}

.TextPercentageTrends {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 25px;
    line-height: 34.15px;
    color: #88DD76;
}

.RowInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.IndexStyle {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    background-color: #E4E4E4;
    padding: 3px 6px;
}

.IndexTextStyle {
    font-family: "Manrope";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000;
}

.RangeTextTreads {
    font-family: "Manrope";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2%;
    color: #2B143C;
    margin-left: 5px;
}

.TextPercentageTable {
    font-family: "Manrope";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2%;
    color: #484848;
    margin-right: 5px;
}