/* Generales */
.nav-borders-top {
    border-top: 1px solid var(--purplish);
}
.nav-borders-bottom {
    border-bottom: 1px solid var(--purplish);
}

@media (max-width: 768px) {
    .container {
        max-width: 100%!important;
    }
}

* {
    font-family: 'Manrope', sans-serif;
}
:root {
      /* Generales */
      --text: #484848;
      --white: #ffffff;
      --clientship: #1D172C;
      --clientship-bg: #010024;
      
      /* grises */
      --grey: #989898;
      --grey-two: ##D9D9D9;
      --grey-tree: #EFEFEF;
      --grey-four: #e5e5e5;
      --grey-five: #f7f7f7;
      
      /* rojos */
      --red: #F9470F;
       --red-two: #B92F1E;
       --red-three: #DA7576;
      
      /* verdes */
      --green: #88DD75;
      --green-two: #019E85;
      --green-three: #8CF0EA;
      
      /* Naranja */
      --orange: #FFA755;

      /* Purpura */
      --purple: #1D172D;      
      --purplish: #423C52;
      --purple-two: #BAAEE5;
      --purple-tree: #3F3166;
      --purple-four: #765DBF;
      --purple-five: #521B7B;
      --purple-six: #C285F0;
      --purple-seven: #3A1B52;
      --purple-eight: #e7e1ff;
    
      /* Anteriores */
       /* --red: #ff0000; */
       /* --green: #019E85; */
       /* --green: #88DD76; */
       /* --green: #17a27d; */
       /* --orange: #FF7410; */
       /* --purple: #602D83; */
       /* --purplish: #3F3065; */
       /* --purple-two: #BAAEE5; */
       /* --purple-tree: #3d305c; */
       
       /* Fuentes */
      --font-clientship: 'Manrope', sans-serif;
    
      /* Tamaños fuentes */
       --big-font: 2rem;
       --medium-font: 1.4rem;
       --small-font: 1rem!important;
       --tiny-font: 0.8rem!important;
       
       /* Bordes */
       --border-default: 13px;
       --border-top-right-radius: 7px;
       --border-top-left-radius: 7px;
       
       /* Sombras */
       --box-shadow-default: 0px 0px 25px 0px rgba(0,0,0,0.1);
}

.wrap-paddings {
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.list-remove-style {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.list-remove-style li {
     margin: 0 0 10px 0;
 }


.small-font {
    font-size: var(--small-font);
    font-family: var(--font-clientship);
}
.tiny-font {
    font-size: var(--tiny-font)!important;
    font-family: var(--font-clientship);
}

/* Colors */
.text-color {
    color: var(--text);
}
.grey-color {
    color: var(--grey);
}
.purple-color {
    color: var(--purple-two);
}

/* Backgrounds */
.background-white {
    background-color: var(--white);
}
.background-red {
    background-color: var(--red);
}
.background-green {
    background-color: var(--green);
    color: var(--clientship-bg)!important;
}
.background-lightgrey {
    background-color: var(--grey-tree);
}
.background-orange {
    background-color: var(--orange);
}
.background-dark {
    background-color: var(--text);
}

.active-green {
    background-color: var(--green)!important;
}
.active-red {
    background-color: var(--red)!important;
}
.active-orange {
    background-color: var(--orange)!important;
}
.active-closed {
    background-color: var(--text)!important;
}

.line-divider {
    color: var(--purple-four)!important;
}

.noradius {
    border-radius: 0!important;
}


/* Nav */
.background-clientship {
    background-color: var(--clientship-bg);
}
.navbar {
    z-index: 9999;
}
.navbar-expand-lg .navbar-nav .nav-link {
    color: var(--white);
    padding-top: 12px;
    padding-bottom: 12px;
}

.nav-borders .container-fluid div ul li.dropdown a {
    border-left: 2px solid var(--purplish);
    padding: 10px 20px;
}

.container-fluid div ul li.dropdown a {
    padding: 10px 20px;
}

.nav-borders .container-fluid div ul li, .container-fluid div ul li {
    padding-left: 5px;
    padding-right: 5px;
}

.nav-borders .container-fluid div ul li.dropdown a, .container-fluid div ul li.dropdown a {
    color: var(--purple-two);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--text)!important;
    background-color: var(--grey-five);
    border-radius: 0px;
}

.navbar-nav .nav-link.show {
   border-top-right-radius: 7px;
   border-top-left-radius: 7px;
}

.filter-fixed {
   margin-top: -1px;
}

.seccion-item {
    border-left: 1px solid #402F64; border-radius: 0; padding-left: 10px;
}

.second-navbar {
    z-index: 3!important;
}

#navbarScroll ul li.dropdown a {
    width: 250px;
    /* border-top-right-radius: 7px;
    border-top-left-radius: 7px; */
}
#navbarScroll ul li.dropdown a::after {
    margin-left: 92px;
}

.dropdown-item {
    color: var(--text)!important;
    padding: 5px 15px 5px 9px!important;
}

.dropdown-item img, .navbar-expand-lg .navbar-nav .nav-link img {
    margin-right: 10px;
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: var(--grey-four)!important;
    color: var(--text)!important;
}

.dropdown-menu {
    background-color: var(--purple)!important;
}

.dropdown .show, .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-top-left-radius: 0;
    border: 0;
    border-radius: 0;
}

.nav-borders .container-fluid div ul li, .container-fluid div ul li {
    padding-left: 0px;
    padding-right: 0px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: var(--grey-five)!important;
    color: var(--text)!important;
    margin: 0!important;
    padding: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li a.dropdown-item {
    padding: 12px 10px!important;
}

.change {
   background: var(--grey-five)!important;
   border-top-left-radius: 7px!important;
   border-top-right-radius: 7px!important;
   margin-top: 3px;
}
.change a.dropdown-toggle {
   color: #484848!important;
}


/* Filters */
.offcanvas-header {
    background-color: var(--clientship);
    color: var(--white);
}
.top-data-range {
    background: transparent;
    border: 0;
    color: var(--purple-two);
    font-size: var(--small-font);
    transition: 0.2s all;
}
.top-data-range span:first-child {
    border-left: 1px solid var(--purple-seven);
    padding-left: 30px;
}
.top-data-range span {
    padding: 16px 25px;
    text-align: center;
    margin: 0;
    border-right: 1px solid var(--purple-seven);
}
.top-data-range span::after {
    display: inline-block;
    margin-left: 15px;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
#initial-date {
    border-left: 0;
}
#filters {
    border-right: 0;
}
#filters::before {
    display: inline-block;
    margin-left: 0px;
    margin-right: 15px;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.top-data-range span:hover {
    background: var(--purplish);
    transition: 0.2s all;
}
.head-search-filter input {
    width: 100%;
    border: 0;
    padding: 10px 10px;
    height: 35px;
}
.head-search-filter input:focus {
    outline: none;
}
.head-check-filter .form-check {
    border-bottom: 1px solid var(--grey-four);
    padding-top: 10px;
    padding-bottom: 10px;
}
.head-check-filter .form-check:last-child {
    border-bottom: 0 solid;
}
.apply-btn {
    background: var(--purple-five);
    border: 0;
    color: var(--white);
    margin: 0;
    padding: 7px;
    text-align: center;
    width: 100%;
    border-radius: 6px;
}
.apply-btn:hover {
    background: var(--purple-seven);
}
.clear-btn {
    background: var(--grey-tree);
    border: 1px solid var(--grey-tree);
    color: var(--text);
    margin: 0;
    padding: 7px;
    text-align: center;
    width: 100%;
    border-radius: 6px;
    transition: 0.2s all;
}
.clear-btn:hover {
    background: var(--white);
    border: 1px solid var(--grey-tree);
    transition: 0.2s all;
}
.offcanvas-header .btn-close {
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
}

/* Main */
main {
    margin: 110px 0 30px;
    background: var(--grey-five);
}

#main {
    width: 100%;
    height:400px;
}

.wrapper {
    max-width: 1300px;
    margin: 30px auto 0;
}

.wrapper-initial {
    max-width: 1300px;
    margin: -80px auto 0!important;
}

#hero {
    width: auto;
    height: 400px;
}

.hero-banner {
    min-height: 650px;
    padding-top: 90px;
    background-color: var(--clientship-bg);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(57,27,80,1) 72%);
}

.active-graph {
    background-color: var(--white)!important;
    pointer-events: none;
    color: var(--purple);
    border: 1px solid #ececec!important;
}
.icon, .icon-inactive {
    width: 13px;
    color: var(--white)!important;
    /* filter: invert(100%) saturate(2380%) brightness(100%) contrast(100%); */
}
.icon-active {
    width: 19px;
    color: var(--purple);
}