.Container-First {
    width: 100%;
    height: 746px;
    background-color: #241D38;
    margin-top: 120px;
}

.Container-RowTables {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 30px;
}

/*SeconTable*/
.StyleContainerRowTrends {
    flex-direction: column;
    margin-left: 175px;
    margin-right: 175px;
}

.TypeRowTrends {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TitleTypeTrends {
    font-size: 12px;
    font-family: "Manrope";
    font-weight: 500;
    line-height: 38px;
    margin-right: 50px;
    color: #241D38
}

.StyleLineTrends {
    width: 1050px;
    height: 2px;
    background-color: #B5A9DA;
    margin-top: 3px;
}

.GraphMapContainer {
    width: 600px;
    height: 275px;
    background-color: red;
}

.RowTablesTopRating {
    display: flex;
    width: 500px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}