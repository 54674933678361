.Container-general {
  background-color: #121547;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Container-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Container-rowLeft {
  display: flex;
  flex-direction: column;
}

.login-container {
  max-width: 850px!important;
}

.Image-top {
  width: 170px;
  height: 49px;
}

.Image-left {
  display: flex;
  background-image: url("../../assets/svg/background.svg");
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Container-rowImage {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Text-logo {
  color: #FFF;
  font-size: 12px;
  font-family: 'Manrope';
  font-weight: 400;
  font-style: normal;
  margin-right: 40px;
  margin-left: 20px;
}

.Text-working {
  color: #819CFF;
  font-size: 12px;
  font-family: 'Manrope';
  font-weight: 400;
  font-style: normal;
  letter-spacing: 3.5px;
  line-height: 12px;
  margin-right: 7px;
}

.Text-together {
  color: #FFF;
  font-size: 12px;
  font-family: 'Manrope';
  font-weight: 400;
  font-style: normal;
  letter-spacing: 3.5px;
  line-height: 12px;
}

.Text-left {
  color: #FFF;
  align-self: flex-start;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
}

.Container-rowRight {
  /* margin-top: 49px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  /* width: 393px; */
  /* height: 345px; */
}

.Title {
  margin-bottom: 23px;
  margin-right: 140px;
  color: #000;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
}

.Input {
  background-color: #F6F6F6;
  width: 298px;
  height: 49px;
  border-radius: 10px;
  margin-bottom: 17px;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.Input::placeholder {
  color: #000;
  opacity: 0.5;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.Button {
  background-color: #333670 !important;
  width: 298px;
  height: 49px;
  border-radius: 10px;
  color: #FFF !important;
  font-family: 'Nunito' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0.435em !important;
  border-color: #333670 !important;
}

@media (min-width: 768px) {
  .Image-left {
    height: 345px;
  }
}


@media (max-width: 767px) {
  .login-container {
    max-width: 380px!important;
  }
  .Image-left {
    height: auto;
  }
}
