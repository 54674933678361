.StyleContainerRow {
    flex-direction: column;
    margin-top: 550px;
}

.TypeRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TitleType {
    font-size: 12px;
    font-family: "Manrope";
    font-weight: 500;
    line-height: 38px;
    margin-right: 50px;
    color: #B7AADC
}

.StyleLine {
    width: 410px;
    height: 2px;
    background-color: #B5A9DA;
    margin-top: 3px;
}

.GraphInfoContainer {
    width: 230px;
    background-color: #3A1B52;
    border-radius: 11px;
    padding: 9px 10px;
}

.SampleInfoStyle {
    width: 101px;
    height: 18px;
    background-color: #5F2F82;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TextSample {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #FFF;
}

.PercentageInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 119px;
    height: 41px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.TextPercentage {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 25px;
    line-height: 34.15px;
    margin-left: 10px;
    color: #FFF;
}

.TextNumberSurvey {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    color: #FFF;
    margin-bottom: 5px;
}

.InfoSurvey {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #AB73D7;
    padding: 9px 0px;
    gap: 6px;
}

.RangeText {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2%;
    color: #C286F1;
}

.QuantityText {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2%;
    color: #FFF;
}

.Container-Second {
    width: 100%;
    height: 2939px;
    background-color: azure;
}