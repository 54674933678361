/* Generales */
.nav-borders-top {
    border-top: 1px solid var(--purplish);
}
.nav-borders-bottom {
    border-bottom: 1px solid var(--purplish);
}

@media (max-width: 768px) {
    .container {
        max-width: 100%!important;
    }
}

* {
    font-family: 'Manrope', sans-serif;
}
:root {
    /* Generales */
    --text: #484848;
    --white: #ffffff;
    --clientship: #1D172C;
    --clientship-bg: #010024;
    
    /* grises */
    --grey: #989898;
    --grey-two: ##D9D9D9;
    --grey-tree: #EFEFEF;
    --grey-four: #e5e5e5;
    --grey-five: #f7f7f7;
    
    /* rojos */
    --red: #F9470F;
     --red-two: #B92F1E;
     --red-three: #DA7576;
    
    /* verdes */
    --green: #88DD75;
    --green-two: #019E85;
    --green-three: #8CF0EA;
    
    /* Naranja */
    --orange: #FFA755;

    /* Purpura */
    --purple: #1D172D;      
    --purplish: #423C52;
    --purple-two: #BAAEE5;
    --purple-tree: #3F3166;
    --purple-four: #765DBF;
    --purple-five: #521B7B;
    --purple-six: #C285F0;
    --purple-seven: #3A1B52;
    --purple-eight: #e7e1ff;
  
    /* Anteriores */
     /* --red: #ff0000; */
     /* --green: #019E85; */
     /* --green: #88DD76; */
     /* --green: #17a27d; */
     /* --orange: #FF7410; */
     /* --purple: #602D83; */
     /* --purplish: #3F3065; */
     /* --purple-two: #BAAEE5; */
     /* --purple-tree: #3d305c; */
     
     /* Fuentes */
    --font-clientship: 'Manrope', sans-serif;
  
    /* Tamaños fuentes */
     --big-font: 2rem;
     --medium-font: 1.4rem;
     --small-font: 1rem!important;
     --tiny-font: 0.8rem!important;
     
     /* Bordes */
     --border-default: 13px;
     --border-top-right-radius: 7px;
     --border-top-left-radius: 7px;
     
     /* Sombras */
     --box-shadow-default: 0px 0px 25px 0px rgba(0,0,0,0.1);
}

.background-clientship {
    background-color: var(--clientship-bg);
}

.navbar {
    z-index: 9999;
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: var(--white);
    padding-top: 12px;
    padding-bottom: 12px;
}

.nav-borders .container-fluid div ul li.dropdown a {
    border-left: 2px solid var(--purplish);
    padding: 10px 20px;
}

.container-fluid div ul li.dropdown a {
    padding: 10px 20px;
}

.nav-borders .container-fluid div ul li, .container-fluid div ul li {
    padding-left: 5px;
    padding-right: 5px;
}

.nav-borders .container-fluid div ul li.dropdown a, .container-fluid div ul li.dropdown a {
    color: var(--purple-two);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--text)!important;
    background-color: var(--grey-five);
    border-radius: 0px;
}

.navbar-nav .nav-link.show {
   border-top-right-radius: 7px;
   border-top-left-radius: 7px;
}

.filter-fixed {
   margin-top: -1px;
}

.seccion-item {
    border-left: 1px solid #402F64; border-radius: 0; padding-left: 10px;
}

.top-navbar {
    z-index: 10;
}
.second-navbar {
    z-index: 3!important;
}

#navbarScroll ul li.dropdown a {
    width: 250px;
    /* border-top-right-radius: 7px;
    border-top-left-radius: 7px; */
}
#navbarScroll ul li.dropdown a::after {
    margin-left: 92px;
}

.dropdown-item {
    color: var(--text)!important;
    padding: 5px 15px 5px 9px!important;
}

.dropdown-item img, .navbar-expand-lg .navbar-nav .nav-link img {
    margin-right: 10px;
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: var(--grey-four)!important;
    color: var(--text)!important;
}

.dropdown-menu {
    background-color: var(--purple)!important;
}

.dropdown .show, .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-top-left-radius: 0;
    border: 0;
    border-radius: 0;
}

.nav-borders .container-fluid div ul li, .container-fluid div ul li {
    padding-left: 0px;
    padding-right: 0px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: var(--grey-five)!important;
    color: var(--text)!important;
    margin: 0!important;
    padding: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li a.dropdown-item {
    padding: 12px 10px!important;
}

.change {
   background: var(--grey-five)!important;
   border-top-left-radius: 7px!important;
   border-top-right-radius: 7px!important;
   margin-top: 3px;
}

.change a.dropdown-toggle {
   color: #484848!important;
}